import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import fairExchangeServices from "../../services/match";
import {
  Table,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Alert,
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
} from "reactstrap";
import User from "../../services/user";
import notify from "../../components/notifier";
import userDetail from "../../services/user";
import moment from "moment";
import CommonNavbar from "../../components/ComonNavbar";
import { Link } from "react-router-dom/cjs/react-router-dom";
import logEventHandler from "../../services/logEventHandler";

const Withdrawl = (props) => {
  const [userId, setuserId] = useState();
  const [tabPaymentType, settabPaymentType] = useState("bank");
  const [deposit, setdeposit] = React.useState({ amount: "", imageproof: "" });
  const [withdrawl, setwithdrawl] = React.useState({
    amount: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    phonepay_upi: "",
    googlepay_upi: "",
    paytm_upi: "",
    user_upi: "",
  });
  const [file, setfile] = React.useState("");
  const [Errors, setErrors] = React.useState({
    amount: "",
    imageproof: "",
    file: "",
    user_upi: "",
  });
  const [withdrawlSubmitted, setwithdrawlSubmitted] = React.useState(false);
  const [Notifications, setNotifications] = React.useState([]);
  const clientInformation = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [userBanks, setUserBank] = useState([]);
  const [activeBank, setActiveBank] = useState({});
  const [btnDisable, setbtnDisable] = useState(false);

  if (
    clientInformation === null ||
    clientInformation.user.PortalName !== "SportsX9"
  ) {
    props.history.push("/");
  }
  const typeChanges = (ptype) => {
    settabPaymentType(ptype);
  };
  useEffect(() => {
    userDetail.userNotifications().then((response) => {
      setNotifications(response.data.data);
    });
    User.userAccountList()
      .then((response) => {
        console.log("response", response.data);
        if (response && response.data) {
          setUserBank(response.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notify(err.response.data, "error", "top-left");
        }
      });

    // User.UserPrimaryAccount()
    //     .then((response) => {
    //         const { account_holder_name, account_number, ifsc_code, upi_number } = response.data;
    //         setwithdrawl({
    //             account_holder_name: account_holder_name,
    //             account_number: account_number,
    //             ifsc_code: ifsc_code,
    //             user_upi: upi_number
    //         });
    //     })
    //     .catch((err) => {
    //         if (err.response.status === 400) {
    //             notify(err.response.data, "error", "top-left");
    //         }
    //     });
  }, []);
  useEffect(() => {
    if (activeBank) {
      const { account_holder_name, account_number, ifsc_code, upi_number } =
        activeBank;
      setwithdrawl({
        account_holder_name: account_holder_name,
        account_number: account_number,
        ifsc_code: ifsc_code,
        user_upi: upi_number,
        amount: withdrawl.amount,
      });
    }
  }, [activeBank]);
  let availablebalanceamount = 0;
  let totalExposure = 0;
  let balancemount = 0;
  props.user &&
    props.user.GamewiseExposure &&
    props.user.GamewiseExposure.forEach((expo) => {
      totalExposure += expo.UsedExposure;
    });

  if (props.user && props.user.BalanceAmount) {
    balancemount = props.user.BalanceAmount;
    if (totalExposure && totalExposure > 1) {
      balancemount = balancemount - totalExposure;
    }
    if (props.user.BonusAmount && props.user.BonusAmount > 0) {
      balancemount =
        parseFloat(balancemount) - parseFloat(props.user.BonusAmount);
    }
  }
  if (balancemount >= 1) {
    availablebalanceamount = balancemount;
  }
  // console.log('availablebalanceamount',availablebalanceamount)
  const handleBankChange = (e) => {
    let selectedbank = e.target.value;
    if (!selectedbank) {
      setActiveBank({});
      setwithdrawl({
        account_holder_name: "",
        account_number: "",
        ifsc_code: "",
        user_upi: "",
        amount: withdrawl.amount,
      });
    }
    if (userBanks && userBanks.length > 0) {
      let bank = userBanks.filter((b) => b._id == selectedbank);
      setActiveBank(bank && bank.length > 0 && bank[0]);
    }
  };
  const handleChange = (e) => {
    if (e.target.name == "amount") {
      if (availablebalanceamount < e.target.value) {
        setErrors({ amount: "Amount can not be more than Balance" });
      } else {
        setErrors({ amount: "" });
      }
    }
    const wth = Object.assign({}, withdrawl);
    wth[e.target.name] = e.target.value;
    setwithdrawl(wth);
  };
  const convertImage = async (file) => {
    let bimg = file;
    setfile(bimg);
  };
  // console.log("hereeee")
  // console.log("withdrawl.googlepay_upi",withdrawl.googlepay_upi.trim().length)
  const validateWithdrawl = (withdrawl, tabPaymentType) => {
    if (!withdrawl.amount || withdrawl.amount == "" || withdrawl.amount < 500) {
      console.log(withdrawl.amount);
      setErrors({ amount: "Amonut can't be empty or less than 500" });
      return false;
    } else if (availablebalanceamount < withdrawl.amount) {
      setErrors({ amount: "Amount can not be more than Balance" });
      return false;
    }
    if (tabPaymentType == "bank") {
      // console.log('withdrawlaccount_number',typeof(withdrawl.account_number))
      if (
        !withdrawl.account_holder_name ||
        withdrawl.account_holder_name == "" ||
        withdrawl.account_holder_name.trim().length == "0"
      ) {
        setErrors({
          account_holder_name: "Account Holder Name can't be empty",
        });
        return false;
      } else if (
        !withdrawl.account_number ||
        withdrawl.account_number == "" ||
        withdrawl.account_number.length == "0"
      ) {
        setErrors({ account_number: "Account Number can't be empty" });
        return false;
      } else if (
        !withdrawl.ifsc_code ||
        withdrawl.ifsc_code == "" ||
        withdrawl.ifsc_code.trim().length == "0"
      ) {
        setErrors({ ifsc_code: "IFSC Code can't be empty" });
        return false;
      }
    } else if (tabPaymentType == "googlepay") {
      if (withdrawl.googlepay_upi == "") {
        setErrors({
          googlepay_upi: "Please Enter Google Pay Number or UPI ID",
        });
        return false;
      }
    } else if (tabPaymentType == "phonepay") {
      if (withdrawl.phonepay_upi == "") {
        setErrors({ phonepay_upi: "Please Enter Phone Pay Number or UPI ID" });
        return false;
      }
    } else if (tabPaymentType == "paytm") {
      if (withdrawl.paytm_upi == "") {
        setErrors({ paytm_upi: "Please Enter Paytm Number or UPI ID" });
        return false;
      }
    } else {
    }
    return true;
  };

  const IncrementAmount = (Amount) => {
    const dep = Object.assign({}, withdrawl);
    let currentamount =
      !dep.amount || dep.amount == "" ? 0 : parseFloat(dep.amount);
    dep["amount"] = parseFloat(currentamount) + parseFloat(Amount);
    setwithdrawl(dep);
  };
  const submitWithdrawl = (e) => {
    setbtnDisable(true);
    e.preventDefault();
    if (!validateWithdrawl(withdrawl, tabPaymentType)) {
      setbtnDisable(false);
      return false;
    }
    let withdrawldata = Object.assign({}, withdrawl);
    withdrawldata["withdrawltype"] = tabPaymentType;
    User.submitWithdrawl(withdrawldata)
      .then((res) => {
        if (res) {
          setwithdrawlSubmitted(true);
          if (
            clientInformation.user &&
            clientInformation.user.RefferCode &&
            clientInformation.user.RefferCode != null
          ) {
            logEventHandler(
              clientInformation.user._id,
              "withdrawal",
              clientInformation.user.ClientName,
              clientInformation.user.RefferCode,
              0,
              withdrawl.amount
            );
          }
          window.scrollTo(0, 0);
          setTimeout(() => {
            setwithdrawlSubmitted(false);
          }, 5000);
          setwithdrawl({
            amount: "",
            account_holder_name: "",
            account_number: "",
            ifsc_code: "",
            phonepay_upi: "",
            googlepay_upi: "",
          });
          localStorage.setItem("withdrawlsuccess", true);
          userDetail.userNotifications().then((response) => {
            setNotifications(response.data.data);
          });
          setbtnDisable(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setbtnDisable(false);
          notify(
            err.response.data && err.response.data.message,
            "error",
            "top-left"
          );
        }
      });
  };
  return (
    <section className="section p-bg-7 deposit-section">
      <div className="row m-0">
        {/* <div className="col-md-12 p-0">
                    <div className="text-center sport-table-header" style={{background:"#1c1f24"}}>
                        <p style={{fontSize:"30px"}}> </p>
                    </div>
                </div> */}
        {/* <div className="col-md-12 p-0">
                    <Table className="bet-table t-width table-bordered responsive hover table p-bg-5" style={{marginBottom: "0px"}}>
                        <thead className="">
                            <tr>
                            <th className="text-center heading-color "
                                style={{ background: tabPaymentType === 'bank' ? '#f6ab04' : '#303030', cursor: "pointer", color: "white"  }}
                                onClick={() => typeChanges('bank')}>
                                    <i className="icons fa fa-bank"></i><br/>Bank Transfer
                            </th>
                            <th  className="text-center heading-color "
                                style={{ background: tabPaymentType === 'googlepay' ? '#f6ab04' : '#303030', cursor: "pointer", color: "white"  }}
                                onClick={() => typeChanges('googlepay')}><i className="icons fa fa-credit-card"></i><br/>GooglePay
                            </th>
                            
                            <th className="text-center heading-color "
                                style={{ background: tabPaymentType === 'phonepay' ? '#f6ab04' : '#303030', cursor: "pointer", color: "white" }}
                                onClick={() => typeChanges('phonepay')}><i className="icons fa fa-credit-card"></i><br/>PhonePay
                            </th>
                            <th className="text-center heading-color "
                                style={{ background: tabPaymentType === 'paytm' ? '#f6ab04' : '#303030', cursor: "pointer", color: "white" }}
                                onClick={() => typeChanges('paytm')}><i className="icons fa fa-credit-card"></i><br/>Paytm
                            </th>
                            </tr>
                        </thead>
                    </Table>
                </div> */}

        <div className="col-lg-12 mb-2">
          <CommonNavbar />
        </div>

        {/* <span className="round-sec b-balance"><span className="v-chip__content"><i className=" fa fa-credit-card"></i> <span className="mr-2">Balance :</span> <span className="primary--text">{props.user && props.user.BalanceAmount && props.user.BalanceAmount.toFixed(2)}</span></span></span>
                <span className="round-sec b-bonus"><span className="v-chip__content"><i className=" fa fa-credit-card"></i> <span className="mr-2">Bonus :</span> <span className="primary--text">{props.user && props.user.BonusAmount && props.user.BonusAmount.toFixed(2)}</span></span></span>
                <span className="round-sec"><span className="v-chip__content"><i className=" fa fa-credit-card"></i> <span className="mr-2">Available withdrawal :</span> <span className="primary--text">{availablebalanceamount && availablebalanceamount.toFixed(2)}</span></span></span> */}

        {withdrawlSubmitted && (
          <div className="col-md-12 form-group">
            <Alert
              color="success"
              style={{
                backgroundColor: "#008000",
                color: "#fff",
                textAlign: "left",
                fontWeight: 800,
                fontSize: "14px",
              }}
            >
              Your withdrawal has been submitted, Money will be transferred
              shortly.
            </Alert>
          </div>
        )}
        <div className="col-lg-12">
          <div className="report-header">
            <div className="report-img-title">
              <div className="report-img-div-title">
                <div className="report-img-div">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    class="report-img"
                  >
                    <g clip-path="url(#clip0_1061_12624)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5 2C11.5 1.17157 10.8285 0.5 10 0.5H2C1.17158 0.5 0.5 1.17157 0.5 2V7C0.5 7.82845 1.17158 8.5 2 8.5H2.5C2.77614 8.5 3 8.27615 3 8C3 7.72385 2.77614 7.5 2.5 7.5H2C1.72386 7.5 1.5 7.27615 1.5 7V4H10.5V7C10.5 7.27615 10.2761 7.5 10 7.5H9.5C9.22385 7.5 9 7.72385 9 8C9 8.27615 9.22385 8.5 9.5 8.5H10C10.8285 8.5 11.5 7.82845 11.5 7V2ZM10.5 3V2C10.5 1.72386 10.2761 1.5 10 1.5H2C1.72386 1.5 1.5 1.72385 1.5 2V3H10.5Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M6.49999 10.9999C6.49999 11.2761 6.27614 11.4999 5.99999 11.4999C5.72384 11.4999 5.49999 11.2761 5.49999 10.9999V8.20339L4.85356 8.84974C4.65828 9.04509 4.34172 9.04509 4.14644 8.84974C3.95119 8.65459 3.95119 8.33794 4.14644 8.14274L5.64619 6.64609C5.84149 6.45114 6.15779 6.45134 6.35294 6.64644L7.85249 8.14604C8.04779 8.34134 8.04779 8.65789 7.85249 8.85319C7.65724 9.04839 7.34064 9.04839 7.14539 8.85319L6.49999 8.20779V10.9999Z"
                        fill="currentColor"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_1061_12624">
                        <rect width="12" height="12" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="report-title">Withdraw</div>
              </div>
              <div class="tab-btns"></div>
            </div>
            <div className="report-filters rh-web-view">
              {/* <div className="withdraw-header-text">
                following payment withdrawal information:
              </div> */}
              <div
                className="withdraw-header-text ml-4"
                style={{
                  color: "white",
                }}
              >
                cashable amount :
                {availablebalanceamount && availablebalanceamount.toFixed(2)}
              </div>
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="num-wraper num">
                                <h5>Available Balance</h5>
                                <h5>{props.user && props.user.BalanceAmount && props.user.BalanceAmount.toFixed(2)}</h5>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="bal-wraper num withdraw-bal">
                                <h5>Bonus</h5>
                                <h5>{props.user && props.user.BonusAmount && props.user.BonusAmount.toFixed(2)}</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="bal-wraper num withdraw-bal">
                                <h5>Cashable Balance</h5>
                                <h5>{availablebalanceamount && availablebalanceamount.toFixed(2)}</h5>
                            </div>
                        </div>

                    </div> */}
          {/* <div className="motions-text withdraw-promo">
                        <h5>Promotions</h5>
                    </div>
                    <div className="row mob-card">
                        <div className="main-card">
                            <div className="col-lg-3 promo-card">
                                <Card className="card-bg">
                                    <CardImg top width="100%" src="/assets/images/Mask group.png" alt="Card image cap" />
                                    <CardBody>
                                        <CardSubtitle className="card-title">First Time Deposit</CardSubtitle>
                                        <CardText className="card-text mt-0">100% Bonus on first time deposit</CardText>
                                        <div className="btn-group mt-2 d-flex">

                                            <Button className="btn-border"><img src="/assets/images/copy_icon.png" alt="" />FTDAUG100</Button>
                                            <Button className="btn-bg">Show Details
                                                <img src="/assets/images/Arrow-3.png" alt="" />
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 promo-card">
                                <Card className="card-bg">
                                    <CardImg top width="100%" src="/assets/images/Mask group.jpg" alt="Card image cap" />
                                    <CardBody>
                                        <CardSubtitle className="card-title">First Time Deposit</CardSubtitle>
                                        <CardText className="card-text mt-0">100% Bonus on first time deposit</CardText>
                                        <div className="btn-group mt-2 d-flex">

                                            <Button className="btn-border"><img src="/assets/images/copy_icon.png" alt="" />FTDAUG100</Button>
                                            <Button className="btn-bg">Show Details
                                                <img src="/assets/images/Arrow-3.png" alt="" />
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 promo-card">
                                <Card className="card-bg">
                                    <CardImg top width="100%" src="/assets/images/teenpati.png" alt="Card image cap" />
                                    <CardBody>
                                        <CardSubtitle className="card-title">First Time Deposit</CardSubtitle>
                                        <CardText className="card-text mt-0">100% Bonus on first time deposit</CardText>
                                        <div className="btn-group mt-2 d-flex">

                                            <Button className="btn-border"><img src="/assets/images/copy_icon.png" alt="" />FTDAUG100</Button>
                                            <Button className="btn-bg">Show Details
                                                <img src="/assets/images/Arrow-3.png" alt="" />
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 promo-card">
                                <Card className="card-bg">
                                    <CardImg top width="100%" src="/assets/images/Mask-dice.jpg" alt="Card image cap" />
                                    <CardBody>
                                        <CardSubtitle className="card-title">First Time Deposit</CardSubtitle>
                                        <CardText className="card-text mt-0">100% Bonus on first time deposit</CardText>
                                        <div className="btn-group mt-2 d-flex">

                                            <Button className="btn-border"><img src="/assets/images/copy_icon.png" alt="" />FTDAUG100</Button>
                                            <Button className="btn-bg me-2">Show Details
                                                <img src="/assets/images/Arrow-3.png" alt="" />
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div> */}
          {/* <div className="col-lg-12 payment-text">
            <h4>Select Payment Method</h4>
          </div> */}

          <div className="row border-around-all">
            <div className="col-lg-3" style={{
              display:"flex"
            }}>
              <div
                className="bank-wraper mb-2"
                style={{
                  background: tabPaymentType === "bank" ? "FFC11C" : "#FFC11C",
                }}
                onClick={() => typeChanges("bank")}
              >
                {/* <p>
                  <img src="/assets/images/Bank.png" alt="Bank" />
                </p> */}
                <h5>Bank</h5>
                {/* <div class="form-check">
                  <Input type="radio" name="radio1" />
                </div> */}
              </div>
              <div className="bank-wraper-color mb-2">
                {/* <p>
                  <img src="/assets/images/cryft.png" alt="Bank" />
                </p> */}
                <h5>Crypto</h5>
                {/* <div class="form-check">
                  <Input type="radio" name="radio1" />
                </div> */}
              </div>
            </div>
            <div className="col-lg-9 border-all">
              <div className="row">
                {/* <div className="col-lg-12">
                  <FormGroup>
                    <ul className="stack-items">
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(2000)}
                      >
                        ₹2000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(4000)}
                      >
                        ₹4000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(8000)}
                      >
                        ₹8000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(10000)}
                      >
                        ₹10,000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(20000)}
                      >
                        ₹20,000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(100000)}
                      >
                        ₹100,000
                      </li>
                    </ul>
                  </FormGroup>
                </div> */}
                <div className="row m-0 p-0">
                  <div className="col-lg-5 mb-2">
                    <div>
                      <span style={{ color: "red" }}>{Errors.amount}</span>
                    </div>
                    <div className="amount-box">
                      <Label for="amount" className="inner-label">
                        Enter Amount
                      </Label>
                      <img
                        className=" pr-2"
                        src="/assets/images/decrement.png"
                        alt=""
                        onClick={() => IncrementAmount(-1)}
                      />
                      <Input
                        type="number"
                        name="amount"
                        className=""
                        id="amount"
                        placeholder="Enter Amount"
                        onChange={handleChange}
                        value={withdrawl.amount}
                        min="500"
                      />

                      <img
                        className=" pl-2"
                        src="/assets/images/increment.png"
                        alt=""
                        onClick={() => IncrementAmount(1)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 mb-2">
                    <div className="amount-boxsecond">
                      <select
                        className="select-box"
                        value={activeBank._id}
                        onChange={(e) => handleBankChange(e)}
                      >
                        <option value={``}>Select Bank</option>
                        {userBanks &&
                          userBanks.length > 0 &&
                          userBanks.map((bank) => (
                            <option value={bank._id}>
                              {bank.account_holder_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="ml-3" style={{
                    border:'1px dotted white',
                    width:"fit-content",
                    marginBottom:"10px"
                  }}>
                    <div style={{
                      padding:"20px"
                    }}>
                      <div style={{
                        height:"30px",
                        width:"30px",
                        background:'white',
                        borderRadius:'50%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        cursor:'pointer',
                        marginLeft:'10px',
                      }}>
                      <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>
                      </div>
                      <div>Add Account</div>
                    </div>
                  </div>
                  {/* <div className="col-lg-2 mb-2">
                    <Link
                      to="/wallet/AccountDetail"
                      className="btn-success next-link"
                    >
                      Add Bank
                    </Link>
                  </div> */}
                </div>
                <div className="row m-0 mt-3 p-0">
                  <div className="col-lg-6">
                    <div className="input-template ">
                      <div className="it-label">Account Holder Name</div>
                      <div className="account-input">
                        <Input
                          type="text"
                          name="account_holder_name"
                          className="it-input"
                          id="account_holder_name"
                          placeholder="Enter Account Holder Name"
                          onChange={handleChange}
                          value={withdrawl.account_holder_name}
                        />
                        <span style={{ color: "red" }}>
                          {Errors.account_holder_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-template ">
                      <div className="it-label">Enter Account Number</div>
                      <div className="account-input">
                        <Input
                          type="number"
                          name="account_number"
                          className="it-input"
                          id="account_number"
                          placeholder="Enter Account Number"
                          onChange={handleChange}
                          value={withdrawl.account_number}
                        />
                        <span style={{ color: "red" }}>
                          {Errors.account_number}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0 account-input">
                  <div className="col-lg-6">
                    <div className="input-template ">
                      <div className="it-label">Enter IFSC Code</div>
                      <div className="account-input">
                        <Input
                          type="text"
                          name="ifsc_code"
                          className="it-input"
                          id="ifsc_code"
                          placeholder="Enter IFSC Code"
                          onChange={handleChange}
                          value={withdrawl.ifsc_code}
                        />
                        <span style={{ color: "red" }}>{Errors.ifsc_code}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-template ">
                      <div className="it-label">Enter UPI</div>
                      <div className="account-input">
                        <Input
                          type="text"
                          name="user_upi"
                          className="it-input"
                          id="user_upi"
                          placeholder="Enter UPI"
                          onChange={handleChange}
                          value={withdrawl.user_upi}
                        />
                        <span style={{ color: "red" }}>{Errors.user_upi}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="submit-depoist">
                      <Button
                        type="submit"
                        className="btn-success"
                        style={{ marginBottom: "10px" }}
                        disabled={btnDisable}
                        onClick={submitWithdrawl}
                      >
                        {btnDisable ? "Please Wait..." : "Submit"}
                      </Button>
                      &nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section end */}
        </div>
        <div className="col-md-12">
          <div className="d-w sport-table-header ">
            <p> Withdrawal History</p>
          </div>
        </div>
        <div className="col-md-12 mb-2">
          <div style={{ width: "100%", overflow: "auto" }}>
            <Table className="account-Notification t-width table-bordered responsive hover table p-bg-5">
              <thead className="thead-dark">
                <tr>
                  <th className="text-left" scope="col">
                    Sr no
                  </th>
                  <th className="text-left" scope="col">
                    Date
                  </th>
                  <th className="text-right" scope="col">
                    Amount
                  </th>
                  <th className="text-right" scope="col">
                    Description
                  </th>
                  <th className="text-right" scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {Notifications.length ? (
                  Notifications.filter((n) => n.withdrawltype).map(
                    (Notification, idx) => (
                      <tr key={Notification._id}>
                        <td>{idx + 1}</td>
                        <td className="text-left">
                          {moment(Notification.CreatedAt).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </td>
                        <td
                          className={`text-right ${
                            Notification.status == 0
                              ? "warning"
                              : Notification.status == 1
                              ? "success"
                              : "danger"
                          }`}
                        >
                          {Notification.amount}
                        </td>
                        <td className="text-right">
                          {" "}
                          {Notification.description &&
                            Notification.description}{" "}
                        </td>
                        <td className="text-right" style={{ color: "green" }}>
                          {Notification.status == 0 ? (
                            <span className="pending-status">Pending</span>
                          ) : Notification.status == 1 ? (
                            <span className="approved-status">Approved</span>
                          ) : (
                            <span className="cancel pending-status">
                              Cancelled
                            </span>
                          )}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">No record found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Withdrawl);
